import { inject }           from 'aurelia-framework';
import { FilesRepository }  from 'modules/administration/files/services/repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class AdditionalInformationFilesRepository extends FilesRepository {
    baseUri = 'management/additional-information/additional-informations/';

    /**
     * Find most recent file related to additional information
     * @param relatableId
     * @returns {*}
     */
    findRelated(relatableId) {
        return this.httpClient.get(this.baseUri + 'files/find-related/' + relatableId);
    }
}
