import { EventAggregator }                      from 'aurelia-event-aggregator';
import { inject }                               from 'aurelia-framework';
import { I18N }                                 from 'aurelia-i18n';
import { UserEntityTeamLotsRepository }         from 'modules/administration/users/user-entity-team-lot/services/repository';
import { AdditionalInformationTypesRepository } from 'modules/management/additional-information/additional-information-types/services/repository';
import { HighwaysRepository }                   from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository }          from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { ConcessionsRepository }                from 'modules/management/specifications/concessions/services/repository';
import { BaseFilterFormSchema }                 from 'resources/classes/base-filter-form-schema';
import { SessionStorage }                       from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ConcessionsRepository, HighwaysRepository, InterventionTypesRepository, UserEntityTeamLotsRepository, AdditionalInformationTypesRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:      [],
        created_at_from: null,
        created_at_to:   null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param additionalInformationTypesRepository
     * @param concessionsRepository
     * @param highwaysRepository
     * @param interventionTypesRepository
     * @param userEntityTeamLotsRepository
     */
    constructor(storage, eventAggregator, i18n, concessionsRepository, highwaysRepository, interventionTypesRepository, userEntityTeamLotsRepository, additionalInformationTypesRepository) {
        super(storage, eventAggregator, i18n);

        this.concessionsRepository                = concessionsRepository;
        this.highwaysRepository                   = highwaysRepository;
        this.interventionTypesRepository          = interventionTypesRepository;
        this.userEntityTeamLotsRepository         = userEntityTeamLotsRepository;
        this.additionalInformationTypesRepository = additionalInformationTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         6,
            remoteSource: () => this.concessionsRepository.allByLoggedUser(),
            observers:    [
                (newValue, oldValue) => {
                    this.highway_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.concessionsRepository.highways(newValue, { filter_by_logged_user: true }).then((response) => {
                            this.highway_id.options = response;
                            this.highway_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.highway_id = {
            type:         'select2',
            key:          'highway_id',
            label:        'form.field.highway-road',
            size:         6,
            remoteSource: () => {
                return viewModel.filterModel.concession_id
                    ? this.concessionsRepository.highways(viewModel.filterModel.concession_id, { filter_by_logged_user: true })
                    : Promise.resolve([]);
            },
            attributes:   {
                disabled: !viewModel?.filterModel?.concession_id && !this.concession_id,
            },
            observers:    [
                (newValue, oldValue) => {
                    this.intervention_type_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.highwaysRepository.interventionTypes(newValue, { filter_by_logged_user: true }).then((response) => {
                            this.intervention_type_id.options = response;
                            this.intervention_type_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.intervention_type_id = {
            type:         'select2',
            key:          'intervention_type_id',
            label:        'form.field.intervention-type',
            size:         6,
            remoteSource: () => {
                return viewModel.filterModel.highway_id
                    ? this.highwaysRepository.interventionTypes(viewModel.filterModel.highway_id, { filter_by_logged_user: true })
                    : Promise.resolve([]);
            },
            attributes:   {
                disabled: !viewModel?.filterModel?.highway_id && !this.highway_id,
            },
            observers:    [
                (newValue, oldValue) => {
                    this.lot_intervention_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.interventionTypesRepository.lotInterventions(newValue, { filter_by_logged_user: true }).then((response) => {
                            this.lot_intervention_id.options = response;
                            this.lot_intervention_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         6,
            remoteSource: () => {
                return viewModel.filterModel.intervention_type_id
                    ? this.interventionTypesRepository.lotInterventions(viewModel.filterModel.intervention_type_id, { filter_by_logged_user: true })
                    : Promise.resolve([]);
            },
            attributes:   {
                disabled: !viewModel?.filterModel?.intervention_type_id && !this.intervention_type_id,
            },
            observers:    [
                (newValue, oldValue) => {
                    if (newValue) {

                        return this.userEntityTeamLotsRepository.searchActiveTeams({
                            lot_intervention_id:   newValue,
                            filter_by_logged_user: true,
                        }).then((response) => {
                            if (response.length > 0) {
                                this.team_id.options             = response;
                                this.team_id.attributes.disabled = false;
                            } else {
                                this.team_id.attributes.disabled = true;
                                viewModel.filterModel.team_id    = null;
                            }
                        });
                    } else {
                        this.team_id.attributes.disabled = true;
                        viewModel.filterModel.team_id    = null;
                    }
                },
            ],
        };

        this.team_id = {
            type:         'select2',
            key:          'team_id',
            label:        'form.field.entity-team',
            size:         6,
            attributes:   {
                disabled: !viewModel?.filterModel?.lot_intervention_id && !this.lot_intervention_id,
            },
            remoteSource: () => {
                return viewModel.filterModel.lot_intervention_id
                    ? this.userEntityTeamLotsRepository.searchActiveTeams({
                        lot_intervention_id:   viewModel.filterModel.lot_intervention_id,
                        filter_by_logged_user: true,
                    }) : Promise.resolve([]);
            },
        };

        this.number_from = {
            type:     'int',
            key:      'number_from',
            label:    'form.field.number(from)',
            size:     4,
            required: false,
        };

        this.number_to = {
            type:     'int',
            key:      'number_to',
            label:    'form.field.number(to)',
            size:     4,
            required: false,
        };

        this.type_ids = {
            type:         'multiselect-native',
            key:          'type_ids',
            label:        'form.field.type',
            size:         4,
            required:     false,
            remoteSource: () => this.additionalInformationTypesRepository.active(),
        };

        this.description = {
            type:     'text',
            key:      'description',
            label:    'form.field.description',
            size:     4,
            required: false,
        };

        this.scope = {
            type:     'text',
            key:      'scope',
            label:    'form.field.scope',
            size:     4,
            required: false,
        };

        this.standard = {
            type:     'text',
            key:      'standard',
            label:    'form.field.standard',
            size:     4,
            required: false,
        };

        this.referencing_data = {
            type:     'text',
            key:      'referencing_data',
            label:    'form.field.referencing-data',
            size:     4,
            required: false,
        };

        this.has_file = {
            type:     'boolean-options-select',
            key:      'has_file',
            label:    'form.field.file',
            size:     4,
            required: false,
        };

        this.validation_status = {
            type:     'boolean-options-select',
            key:      'validation_status',
            label:    'form.field.validated',
            size:     4,
            required: false,
        };

        this.created_by = {
            type:     'user-entity-team-lot-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     4,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     4,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     4,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.concession_id, this.highway_id],
            [this.intervention_type_id, this.lot_intervention_id],
            [this.team_id],
            [this.number_from, this.number_to, this.type_ids],
            [this.description, this.scope, this.standard],
            [this.referencing_data, this.has_file, this.validation_status],
            [this.created_by, this.created_at_from, this.created_at_to],
            [this.buttons],
        ];
    }
}
