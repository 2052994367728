import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class AdditionalInformationsRepository extends BaseRepository {
    baseUrl = 'management/additional-information/additional-informations';

    /**
     * Closes a given record
     *
     * @param id
     * @return {*}
     */
    close(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/close`);
    }

    /**
     * Reopens a given record
     *
     * @param id
     * @return {*}
     */
    reopen(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/reopen`);
    }

    /**
     * Reopens selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    reopenSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/reopen-selected`, { selectedRows });
    }

    /**
     * Closes selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    closeSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/close-selected`, { selectedRows });
    }

    /**
     * Generates a zip with the record's files
     *
     * @returns {*}
     */
    generateZip(criteria) {
        return this.httpClient.post(`${this.baseUrl}/generate-zip`, criteria);
    }

    /**
     * Fetches the next (suggested) number
     *
     * @returns {*}
     */
    nextNumber() {
        return this.httpClient.get(`${this.baseUrl}/next-number`);
    }

    /**
     * Fetches all designs from a given lot
     *
     * @param lotInterventionId
     * @return {*}
     */
    allDesignsFromLotIntervention(lotInterventionId) {
        return this.httpClient.get(`${this.baseUrl}/${lotInterventionId}/designs`);
    }

    /**
     * Performs a search given some criteria, returns list
     *
     * @param criteria
     *
     * @returns {*}
     */
    searchMap(criteria) {
        return this.httpClient.post(`${this.baseUrl}/search-map`, criteria);
    }

    /**
     * Validate a given record
     *
     * @param id
     * @return {*}
     */
    validate(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/validate`);
    }

    /**
     * Invalidate a given record
     *
     * @param id
     * @return {*}
     */
    invalidate(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/invalidate`);
    }

    /**
     * Validate selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    validateSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/validate-selected`, { selectedRows });
    }

    /**
     * Invalidate selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    invalidateSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/invalidate-selected`, { selectedRows });
    }
}
